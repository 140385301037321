.inroduction-main {
    background-color: #99c7e3;
    font-size: 16px;
}

.inroduction-main-text {
    color: black;
}

.title-text {
    font-weight: lighter;
    color: darkblue;
    font-family: "黑体";
    text-shadow: 2px 2px 3px #fff;
    margin-top: 16px;
    margin-bottom: 0px;
}

.rule-container {
    width: 75%;
    margin: 0px auto 40px auto;
}

.rule-container img{
    width: 90%;
    max-width: 500px;
}

ol.rule-list {
    text-align: left;
}

ol.rule-list li{
    padding-top: 10px;
}

.container {
    display: flex;
    width: 100%;
    max-width: 800px;
    margin: 10px auto 0px auto;
}

.large-character {
    color: black;
    flex-shrink: 0;
    width: auto;
    font-size: 96px;
    text-align: center;
    line-height: 100px;
    margin-right: 20px;
}

.description {
    flex: 1;
    padding: 20px;
    text-align: left;
}

.image-wall {
    overflow: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: 80%;
    max-width: 800px;
    margin: auto;
}

.image-wall-scroll {
    display: flex;
    width: max-content;
    height: 150px;
    animation: scroll 15s linear infinite;
}

.image-wall-scroll:hover {
    animation-play-state: paused;
}

.image-wall-scroll img {
    height: 100%;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}