@media only screen and (max-width: 600px){
  .main-cotent{
    padding: 0 0;
  }
  .large-text,.title-text {
  font-size: 1.5rem; 
  }
  .number-text {
  font-size: 2.5rem;
  }
  .ant-layout-header{
    padding: 0 10px;
  }
  .main-img{
    width: 100vw;
    height: auto;
  }
}

@media only screen and (min-width: 600px){
  .main-cotent{
    padding: 0 50px;
  }
  .large-text,.title-text {
  font-size: 2rem; 
  }
  .number-text {
  font-size: 4rem;
  }
  .main-img{
    width: 50vw;
    height: auto;
  }
}

.site-layout-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  min-height: 280px;
  text-align: center;
}

.logo {
  margin: 8px 24px 0px 0;
  float: left;
}

.large-text {
  font-weight: lighter;
  color: #ffffff;
  font-family: "SimSun","宋体",serif;
  text-shadow: 2px 2px 3px #000;
  margin: 16px 0;
}

.number-text {
  font-weight: bolder;
  color: #ffffff;
  font-family: "SimSun","宋体",serif;
  text-shadow: 2px 2px 3px #000;
  margin: 16px 0;
}

.fix-text {
  font-size: 1rem;
  font-weight: lighter;
  color: #ffffff;
  font-family: "SimSun","宋体",serif;
  text-shadow: 2px 2px 3px #000;
  margin: 16px 0;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  text-align: center;
}

.ant-card-head-title {
  text-align: center;
  width: 100%;
  font-size: 16px;
}
.ant-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
}

.main-btn{
  background: #0b2636;
  margin: 16px 0;
  font-size: 1.5rem;
}
