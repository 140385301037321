.ant-table-thead .ant-table-cell{
  background-color: #0b2636 !important; 
  color: #ffffff !important;
}

.vote-title{
  text-align: center;
  color: #ffffff !important;
  text-shadow: 2px 2px 3px #000;
}

.color-qualified{
  background-color: #38b;
}

.color-playoff{
  background-color: #99c7e3;
}

.table-card > .ant-card-head{
    background-color: #0b2636;
    color: #ffffff; 
}
.table-card > .ant-card-body{
    background-color: #38b;
}