.list-card{
    background-color: #38b;
    border-width: 2px;
}
.list-card > .ant-card-body{
    background-color: #38b;
}

.list-card > .ant-card-head{
    padding-top: 1rem;
}

.list-card-meta > .ant-card-meta-detail > .ant-card-meta-title{
    color: #ffffff;
    font-size: 1.2rem;
    font-family: "SimSun","宋体",serif;
    text-shadow: 2px 2px 3px #000;
    font-weight: lighter;
}

.list-card-meta > .ant-card-meta-detail > .ant-card-meta-description{
    color: #0b2636;
    height: 3rem;
}
